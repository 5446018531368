<template>
  <div class="home-product-info">
    <div class="home-product-info-head">
      <span>
        <v-img v-if="type == 'soc'" :attachment-id="productDetail.socImg" :default-img="require('@/assets/img/kctp.png')" />
        <v-img v-else-if="type == 'dev'" :attachment-id="productDetail.attmIds" :default-img="require('@/assets/img/kctp.png')" />
        <v-img v-else :attachment-id="productDetail.osImg" :default-img="require('@/assets/img/kctp.png')" />
      </span>
      <div>
        <div class="home-product-info-head-title">
          <span>{{productDetail.name}}</span>
        </div>
        <p v-html="productDetail.discription"></p>
        <div class="home-product-info-head-share">
          <p>
            <span v-if="type != 'os'">
              厂商：
              <template v-if="type  == 'dev'">
                <span v-for="item in productDetail.datIsvs" :key="item.id">{{item.name}}</span>
              </template>
              <template v-else>{{productDetail.socCorpName}}</template>
            </span>
            <span v-else>服务商：{{productDetail.osCorpName}}</span>
            <span>更新时间：{{productDetail.publishTime | moment('YYYY-MM-DD')}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="home-product-info-content">
      <div class="home-product-basic-info">
        <p>基本信息</p>
        <div>
          <template v-if="type == 'soc'">
            <div>
              <span>名称</span>
              <p>{{productDetail.name}}</p>
              <span>系列</span>
              <p>{{productDetail.setNum || '/'}}</p>
            </div>
            <div>
              <span>型号</span>
              <p>{{productDetail.modelNum}}</p>
              <span>厂商</span>
              <p>{{productDetail.socCorpName}}</p>
            </div>
          </template>
          <template v-else-if="type == 'dev'">
            <div>
              <span>名称</span>
              <p>{{productDetail.name}}</p>
              <span>嵌入式OS</span>
              <p><span v-for="i in productDetail.datOs" :key="i.id" @click="protInfo(i.id,'os')">{{i.name}}</span></p>
            </div>
            <div>
              <span>SOC</span>
              <p><span v-for="i in productDetail.datSocs" :key="i.id" @click="protInfo(i.id,'soc')">{{i.name}}</span></p>
              <span>型号</span>
              <p>{{productDetail.modelNum}}</p>
            </div>
            <div>
              <span>系统级别</span>
              <p>{{productDetail.grade == '01'?'轻量系统':''}}{{productDetail.grade == '02'?'小型系统':''}}{{productDetail.grade == '03'?'标准系统':''}}</p>
              <span>厂商</span>
              <p style="flex-wrap: wrap;"><span v-for="item in productDetail.datIsvs" :key="item.id" style="width:100%">{{item.name}}</span></p>
            </div>
            <div>
              <span>代码仓地址</span>
              <p>{{productDetail.codeUrl}}</p>
            </div>
          </template>
          <template v-else>
            <div>
              <span>名称</span>
              <p>{{productDetail.name}}</p>
              <span>系统板本</span>
              <p>{{productDetail.sysVersion}}</p>
            </div>
            <div>
              <span>适配SOC</span>
              <p>{{productDetail.socMapping}}</p>
              <span>服务商</span>
              <p>{{productDetail.osCorpName}}</p>
            </div>
          </template>
          <div>
            <span>应用场景</span>
            <p>{{productDetail.sceneTagName}}</p>
          </div>
        </div>
      </div>

      <div class="home-product-info-text" v-if="type == 'dev'">
        <p>特性</p>
        <div v-for="item in productDetail.propertyJson" :key="item.key">{{item.value}}</div>
      </div>
      <div class="home-product-info-text" v-if="type == 'os'">
        <p>特性说明</p>
        <div>
          <p v-for="(item,index) in productDetail.params.split('\n')" :key="index">{{item}}</p>
        </div>
      </div>
      <div class="home-product-basic-info" v-if="(type == 'soc' || type == 'dev') && productDetail.paramsJson != null">
        <p>规格参数</p>
        <div>
          <div v-for="item in productDetail.paramsJson" :key="item.key">
            <span>{{item.name}}</span>
            <p>{{item.value}}</p>
          </div>
        </div>
      </div>
      <div class="home-product-info-text" v-if="productDetail.detail">
        <p>详细介绍</p>
        <div v-html="productDetail.detail"></div>
      </div>
      <div class="home-product-info-plan" v-if="solutionList != ''">
        <p>相关产品&方案</p>
        <div>
          <div v-for="item in solutionList" :key="item.id" @click="$router.push(`/solution/info?id=${item.id}`)">
            <v-img :attachment-id="item.solutionImg " :default-img="require('@/assets/img/kctp.png')" />
            <p>{{item.name}}</p>
          </div>
        </div>
      </div>
      <template v-if="productDetail.isHarmony == 1">
        <div class="home-product-info-text" v-if="productDetail.hmAdv">
          <p>鸿蒙优势</p>
          <div v-html="productDetail.hmAdv"></div>
        </div>
        <div class="home-product-info-text" v-if="productDetail.hmFrameworkImg">
          <p>鸿蒙架构</p>
          <div><v-img :attachment-id="productDetail.hmFrameworkImg " :default-img="require('@/assets/img/kctp.png')" /></div>
        </div>
        <div class="home-product-info-text" v-if="type == 'soc' && productDetail.hmFunctionImg">
          <p>鸿蒙功能框图</p>
          <div><v-img :attachment-id="productDetail.hmFunctionImg " :default-img="require('@/assets/img/kctp.png')" /></div>
        </div>
        <div class="home-product-info-text" v-if="productDetail.hmCase != ''">
          <p>鸿蒙方案</p>
          <div v-html="productDetail.hmCase"></div>
        </div>
      </template>
    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getIotSocInfo,getIotOsInfo,getIotDevInfo,getIotKnowledgeChooseList,getDatSolutionList } from "@/api/iot";
import VImg from "@/components/VImg";
import { mapGetters } from "vuex";
import share from '../components/share.vue'
export default {
  components:{
    VImg,
    share
  },
  computed: {
    ...mapGetters(["userInfo"]),
    id: function () {
      return this.$route.query.id;
    },
    type: function () {
      return this.$route.query.type;
    },
  },
  data(){
    return{
      productDetail:'',
      page:{
        current: 1,
        size: 9,
        pageSize: 1,
      },
      knowledgeList:[],
      solutionList:[],
      isFocus:false,
      shareInfo: {
          title: '',
          desc: '',
          img: require('@/assets/img/logoinimg.png')
      }
    }
  },
  watch:{
    id:function(val){
      this.loadGetInfo()
    }
  },
  mounted(){
    document.title = "享｜开发资源";
    this.loadGetInfo()
    // this.loadgetIotKnowledgeList()
  },
  methods:{
    loadGetInfo(){
      let {type,id} = this
      if(type == 'soc'){
        getIotSocInfo(id).then((res)=>{
          if (res.hmAdv == "<p><br></p>") {
            res.hmAdv = '';
          }
          if (res.hmCase == "<p><br></p>") {
            res.hmCase = '';
          }
          if (res.detail == "<p><br></p>") {
            res.detail = '';
          }
          this.productDetail = res
          this.shareInfo.title = res.name
          this.shareInfo.desc = res.discription
          this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.socImg}`
          this.loadgetDatSolutionList(res.solIds)
        })
      }else if(type == 'dev'){
        getIotDevInfo(id).then((res)=>{
          if (res.hmAdv == "<p><br></p>") {
            res.hmAdv = '';
          }
          if (res.hmCase == "<p><br></p>") {
            res.hmCase = '';
          }
          if (res.detail == "<p><br></p>") {
            res.detail = '';
          }
          this.productDetail = res
          this.shareInfo.title = res.name
          this.shareInfo.desc = res.discription
          this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.attmIds}`
          this.loadgetDatSolutionList(res.solIds)
        })
      }else{
        getIotOsInfo(id).then((res)=>{
          if (res.hmAdv == "<p><br></p>") {
            res.hmAdv = '';
          }
          if (res.hmCase == "<p><br></p>") {
            res.hmCase = '';
          }
          if (res.detail == "<p><br></p>") {
            res.detail = '';
          }
          this.productDetail = res
          this.shareInfo.title = res.name
          this.shareInfo.desc = res.discription
          this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.osImg}`
          this.loadgetDatSolutionList(res.solIds)
        })
      }

    },
    protInfo(id,type){
      let _this = this
      this.$router.push({
        path: "/replace/productInfo",
        query: { id,type },
      });
      setTimeout(() => {
        _this.loadGetInfo()
      }, 100);
    },
    loadgetDatSolutionList(solIds){
      if(solIds){
        let data = {
          solIds
        }
        getDatSolutionList(data).then((res)=>{
          this.solutionList = res
        })
      }
    },
    loadgetIotKnowledgeList(){
      let {page,id,type} = this
      let skuType = 0
      if(type == 'soc'){
        skuType = 2
      }else if(type == 'dev'){
        skuType = 1
      }else if(type == 'os'){
        skuType = 3
      }
      let data = {
        skuId:id,
        skuType
      }
      getIotKnowledgeChooseList(page,data).then((res)=>{
        console.log(res)
        this.knowledgeList = [...this.knowledgeList,...res.records]
        this.page.pageSize = res.pages
      })
    },
    // 加载跟多
    knowledgeAll(){
      let {page} = this
      if(page.current == page.pageSize){
        this.$message.warning('暂无更多内容');
        return
      }
      page.current ++
      this.loadgetIotKnowledgeList()
    },

    knowledgeUrl(url){
      window.open(url)
    }
  }
};
</script>

<style lang="scss" scoped>
.home-product-info {
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  .home-product-info-head{
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    >span{
      width: 100%;
      height: 140px;
      margin-bottom: 16px;
      >img{
        max-width: 100%;
        height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    >div{
      flex: 1;
      .home-product-info-head-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        > span {
          font-size: 18px;
          line-height: 24px;
          color: #333;
        }
      }
      >p{
        color: #666;
        line-height: 22px;
        margin-bottom: 0;
        font-size: 14px;
      }
      .home-product-info-head-share{
        display: flex;
        flex-wrap: wrap;
        >p{
          display: flex;
          color: #999;
          flex-wrap: wrap;
          >span{
            width: 100%;
            line-height: 18px;
            font-size: 14px;
            margin-top: 12px;
          }
        }
      }
    }
  }
  .home-product-info-content{
    background: #fff;
    >div{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }
      >p{
        border-left: 4px solid #EA0B06;
        padding-left: 12px;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 16px;
        font-weight: bold;
      }
    }
    .home-product-basic-info{
      >div{
        border: 1px solid #eee;
        background: #F1F6FA;
        border-right: 0;
        >div{
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid #eee;
          &:last-child{
            border-bottom: 0;
          }
          &:nth-child(2n){
            background: #fff;
          }
          >span{
            width: 24%;
            min-height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #eee;
            font-weight: bold;
            padding: 0 8px;
            word-break: break-all;
            font-size: 14px;
          }
          >p{
            font-size: 14px;
            width: 76%;
            border-right: 1px solid #eee;
            min-height: 26px;
            display: flex;
            align-items: center;
            padding: 16px;
            line-height: 24px;
            margin-bottom: 0;
            word-break: break-all;
            >span{
              cursor: pointer;
              transition: all .3s;
              &:hover{
                color: #EA0B06;
              }
            }
          }
        }
      }
    }
    .home-product-info-plan{
      >div{
        // display: flex;
        >div{
          width: 100%;
          margin-bottom: 24px;
          cursor: pointer;
          &:last-child{
            margin-right: 0;
          }
          >img{
            max-width: 100%;
            height: 216px;
            display: block;
            margin-bottom: 16px;
          }
          >p{
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
    .home-product-info-text{
      >div{
        line-height: 22px;
        text-align: justify;
        word-break: break-all;
        font-size: 14px;
        >img{
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .home-product-knowledge{
      >p{
        margin-bottom: 0;
      }
    }
    .home-product-knowledge-list {
      > div {
        padding: 24px 0;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        .home-search-knowledge-title {
          display: flex;
          margin-bottom: 20px;
          > span {
            height: 20px;
            border: 1px solid #EA0B06;
            line-height: 18px;
            padding: 0 6px;
            color: #EA0B06;
            margin-right: 12px;
            &.sp{
              border-color: #1296DB;
              color: #1296DB;
            }
            &.dm{
              color: #28C445;
              border-color: #28C445;
            }
          }
          > p {
            font-size: 16px;
            line-height: 16px;
            max-width: 770px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        > span {
          color: #8c8c8c;
          line-height: 22px;
          display: block;
          max-height: 44px;
          text-align: justify;
          overflow: hidden;
          margin-bottom: 20px;
        }
        .home-search-knowledge-date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #bfbfbf;
          > span {
            line-height: 14px;
          }
          > p {
            display: flex;
            align-items: center;
            > span {
              line-height: 14px;
              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
    .home-search-knowledge-page {
      height: 40px;
      text-align: center;
      line-height: 40px;
      > span {
        cursor: pointer;
        color: #EA0B06;
      }
    }
  }
}
</style>
<style>
.home-product-info-breadcrumb .ant-breadcrumb a:hover {
  color: #EA0B06;
}
.home-product-info-head>div>p p{
  word-break: break-all;
}
.home-product-info-text>div img{
  max-width: 100%;
}
</style>
